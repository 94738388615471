import {useEffect, useState} from "react";
// import "./styles.css";

export default function Suggestions() {
    const [texts, setTexts] = useState([]);

    useEffect(() => {
        const timeoutIds = [];

        [
            'Your Parents?',
            'Your Brother?',
            'Your Sister?',
            'A friend?',
            'Work colleagues?',
            'Everyone?',
            'A Therapist?',
            'Remember, someone does care.'
        ].forEach((text, i) => {
            const timeoutId = setTimeout(() => {
                setTexts((prev) => [text]); // [...prev, text]);
            }, 2000 * i);

            timeoutIds.push(timeoutId);
        });

        return () => {
            timeoutIds.forEach((id) => clearTimeout(id));
        };
    }, []);

    return (
        <div>
            {texts.map((text) => (
                <p key={text}>{text}</p>
            ))}
        </div>
    );
}
