import './App.css';
import Suggestions from "./Suggestions";

function App() {
    return (
        <div className="App">
            <h1>Who Gives A Fuck?</h1>
            <small>A question we ask from time to time.</small>
            <br/>
            <Suggestions/>
            <div id="yes">

                <a className="button" href="https://findahelpline.com/" rel="noopener" target="_blank">Find Help</a>
            </div>
        </div>
    );
}

export default App;
